<template>
  <div class="w-p">
    <div class="worker-card-list">
      <worker-card class="worker-card-item" :data="worker">
      </worker-card>
    </div>
    <div class="j-t-list">
      <div class="j-t-i" @mouseover="showIndex = index" @mouseout="showIndex = null" v-for="(item, index) in dataList" :key="index">
        <div class="j-t-ic">
          <i class="fmico-success fmico" v-if="item.isMatch"></i>
          <i class="fmico-error fmico" v-else></i>
        </div>
        <div>{{item.jobTitle.type + item.jobTitle.title + '级'}}</div>
        <fm-poper :value="showIndex === index" position="bottom">
          <div class="p-p-c">
            <promotion-condition :data="item"></promotion-condition>
          </div>
        </fm-poper>
      </div>
      <div class="j-t-i" v-if="current">
        <div class="j-t-ic">
          <i class="icon-a-zu1365 iconfont"></i>
        </div>
        <div>{{current.jobTitleType + current.jobTitleTitle + '级'}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import WorkerCard from './workerCard'

import PromotionCondition from './promotionCondition'

export default {
  components: {
    WorkerCard,
    PromotionCondition
  },
  props: {
    worker: { type: Object, default: () => ({}) },
    dataList: Array,
    current: Object
  },
  data () {
    return {
      showIndex: null
    }
  }
}
</script>

<style lang="less" scoped>
.w-p {
  display: flex;
}
.j-t-list {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  min-height: 200px;
  padding: 0 20px;
}
.p-p-c {
  background: #fff;
  box-shadow: 1px 1px 5px #888888;
  padding: 10px 10px 10px 20px;
  border-radius: 5px;
}
.j-t-i {
  width: 150px;
  min-width: 150px;
  height: 80px;
  margin-right: 20px;
  margin-bottom: 20px;
  background: #FAFAFA;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0;
  .j-t-ic {
    flex: 1;
    i {
      font-size: 25px;
    }
    .fmico-success {
      color: #3cbf50;
    }
    .fmico-error {
      color: #d1230c;
    }
    .icon-a-zu1365 {
      color: #3fabf4;
    }
  }
}
</style>
