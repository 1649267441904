<template>
  <div class="p-c">
    <div class="c-item" v-for="(item, index) in data.conditionList" :key="index">
      <div class="c-item-text" v-if="item.type === 'HisHighestWorkerJobTitle'">最高聘任岗位大于等于当前等级({{data.hisHighestWorkerJobTitle.jobTitleType + ' ' + data.hisHighestWorkerJobTitle.jobTitleTitle + '级'}})</div>
      <div class="c-item-text" v-if="item.type === 'certificate'">
        {{data.workerCertificate ? data.workerCertificate.certificateTitle : '无匹配专业技术资格证书'}}
      </div>
      <div class="c-item-text" v-if="item.type === 'countYear'">
        {{data.jobTitle.type + data.jobTitle.needMLevel + '工作' + (item.isMatch ? '' : '未') + '满' + data.jobTitle.needYear + '年'}}
      </div>
      <div class="c-item-text" v-if="item.type === 'countJobTitleYear'">
        {{data.jobTitle.needJobTitleType + data.jobTitle.needJobTitleTitle + '级工作' + (item.isMatch ? '' : '未') + '满' + data.jobTitle.needJobTitleYear + '年'}}
      </div>
      <div class="c-item-i">
        <i class="fmico-success fmico" v-if="item.isMatch"></i>
        <i class="fmico-error fmico" v-else></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Object, default: () => ({}) }
  }
}
</script>

<style scoped lang="less">
.p-c {
  width: 180px;
  color: rgba(0, 0, 0, 0.7);
}
.c-item {
  margin-top: 10px;
  .c-item-text {
    flex: 1;
    font-size: 13px;
  }
  .c-item-i {
    width: 30px;
  }
  display: flex;
  align-items: center;
  .fmico-success {
    font-size: 18px;
    color: #3cbf50;
  }
  .fmico-error {
    font-size: 18px;
    color: #d1230c;
  }
}
</style>